<template>
  <div>
    <v-btn icon @click="$router.go(-1)" class="ml-minus-2">
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>
    <h1 class="my-5 font-weight-medium">{{ $t('views.Deposit.title') }}</h1>
    <ProfileCard />
    <h2 class="font-weight-medium mt-3">{{ $t('views.Deposit.subtitle') }}</h2>
    <div v-html="$t('views.Deposit.desc')" class="mt-3" />

    <!-- UAT Trigger Minimum Deposit -->
    <div v-if="testEnvironment">
      <br><br><br><br><br>
      <v-card
        align="center"
        color="element"
      >
        <v-card-subtitle>
          {{ $t('onboarding.investorApproval.triggerMinimumDeposit.info') }}
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-btn
              color="primary"
              class="black--text"
              large
              @click="triggerMinimumDeposit"
            >
              {{ $t('onboarding.investorApproval.triggerMinimumDeposit.button') }}
            </v-btn>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { triggerMinimumDeposit as trigger } from '@/api/testing';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Deposit',
  components: {
    ProfileCard: lazyLoad('components/Shared/Cards/ProfileCard'),
  },
  computed: {
    ...mapGetters('application', [
      'configurations',
    ]),
    testEnvironment() {
      return this.configurations?.stage === 'uat';
    },
  },
  methods: {
    async triggerMinimumDeposit() {
      await trigger();
    },
  },
};
</script>
